import moment from 'moment';
import { dbmodel } from "./dbmodel.js";
import { apiAlunos } from "./api-alunos.js";

export const pluginTool = {

  parseScriptToObj(obj) {

    var objScript = {};
    //console.log("parseScriptToObj",obj);
    for(var key in obj) {
      if(obj[key].function != undefined) {
        //console.log("function "+key,obj[key]);
        var f = obj[key];
        objScript[key] = new Function(f.params.join(','),f.body);
      } else {
        if(key == "methods") {
          objScript[key] = {};
          for(var key2 in obj[key]) {
            if(obj[key][key2].function) {
              //console.log("METHODS function "+key2,obj[key][key2]);
              var f = obj[key][key2];
              objScript[key][key2] = new Function(f.params.join(','),f.body);
            }
          }
        }
      }
    };
    objScript.created = function() {
      var self = this;
      //console.log("component plugin CREATED!");
      this.$emit('iniciar', {
        cbfunc: function(dados,rdb) {
          //console.log("cbfunc rdb",rdb);
          //self.super = dados;
          //self.super.rdb = rdb;
          self.appIntegra = {
            moment, dbmodel, apiAlunos
          }
        }
      })
    };

    //console.log("objScript",objScript);
    return objScript;
  }
}
