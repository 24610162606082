
import {
  VCard, VRow, VCol, VContainer, VBtn, VAvatar, VImg, VIcon, VAlert,
  VBadge, VBanner, VChip, VCheckbox, VChipGroup, VColorPicker, VCombobox,
  VContent, VCounter, VData, VDialog, VDivider, VExpansionPanel, VFileInput,
  VFooter, VForm, VHover, VInput, VLabel, VList, VMenu, VMessages,
  VNavigationDrawer, VOverlay, VPagination, VPicker, VProgressCircular,
  VProgressLinear, VRadioGroup, VRangeSlider, VRating, VResponsive,
  VSelect, VSheet, VSkeletonLoader, VSlideGroup, VSlider, VSnackbar, VSparkline,
  VSpeedDial, VStepper, VSwitch, VSystemBar, VTabs, VTextarea, VTextField, VTimeline,
  VTimePicker, VToolbar, VTooltip, VTreeview, VVirtualScroll, VWindow
} from 'vuetify/lib'
import appintegraplugin from "@/components/plugins/app-integra-plugin.vue";
import fileupload from "./fileupload.vue";

export function vuetifyComponents() {
  return {
    VCard, VRow, VCol, VContainer, VBtn, VAvatar, VImg, VIcon, VAlert,
    VBadge, VBanner, VChip, VCheckbox, VChipGroup, VColorPicker, VCombobox,
    VContent, VCounter, VData, VDialog, VDivider, VExpansionPanel, VFileInput,
    VFooter, VForm, VHover, VInput, VLabel, VList, VMenu, VMessages,
    VNavigationDrawer, VOverlay, VPagination, VPicker, VProgressCircular,
    VProgressLinear, VRadioGroup, VRangeSlider, VRating, VResponsive,
    VSelect, VSheet, VSkeletonLoader, VSlideGroup, VSlider, VSnackbar, VSparkline,
    VSpeedDial, VStepper, VSwitch, VSystemBar, VTabs, VTextarea, VTextField, VTimeline,
    VTimePicker, VToolbar, VTooltip, VTreeview, VVirtualScroll, VWindow,
    appintegraplugin, fileupload
  }
};
