<template>
  <div class="">
    <component v-if="componentError == 0 && getComponent != null" :is="getComponent" :key="refresh" @iniciar="iniciar" @cliquei="botao"/>
    <v-container v-if="componentError > 0">
      <v-row>
        <v-col cols="12" md="6" lg="4">
          <v-card class="p-2 text-center" color="grey lighten-4">
            <p class="m-0 p-0">
              Oh! No!
              <v-icon class="material-icons-two-tone grey-text text-darken-1">sentiment_dissatisfied</v-icon>
            </p>
            <hr class="m-0 p-0">
            <p class="m-0 p-0 mt-1 fs-9pt">info:</p>
            <p class="m-0 p-0 mt-1">
              <span class="fs-8pt fw-500">pluginName:</span>
              <span class="ms-1 fw-300">{{pluginName}}</span>
            </p>
            <p class="m-0 p-0 mt-1">
              <span class="fs-8pt fw-500">Erro:</span>
              <span class="ms-1 fw-300">plugin não encontrado</span>
            </p>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import { plugin } from './plugin.js'
import { pluginTool } from './plugin-tools.js'
import { vuetifyComponents } from "./vuetify-components.js"

export default {
  name: "",
  components: {},
  props: {
    pluginName: { default: "", type: String },
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      comp: null,
      componentError: 0,
    }
  },
  watch: {},
  computed: {
    getComponent() {
      if(this.comp != null) {
        return this.comp;
      } else {
        return null;
      }
    }
  },
  mounted: function() {
    var self = this;

    rdb.ref('/appIntegra/plugins/plugin-'+this.pluginName).on('value', function(snapshot) {
      self.comp = null;
      if(snapshot.val() == null) {
        self.componentError = 1;
        return;
      } else {
        self.componentError = 0;
      }
      var plugin = clone2(snapshot.val());
      self.log("plugin encontrado: "+self.pluginName);
      var component = pluginTool.parseScriptToObj(plugin.script);
      //console.log("plugin.template",plugin.template);
      component.template = atob(plugin.template);
      component.components = vuetifyComponents();
      //component.components = { VCard, VRow, VCol, VBtn, VImg, VContainer, VIcon };
      //component.components['VAlert'] = () => import('vuetify/lib/components/VAlert/VAlert');

      //console.log("component",component);
      self.comp = component;
      self.getComp = function(resolve, reject) {
        var fun2 = new Function('self', 'return '+ self.comp);
        self.comp = fun2(self);
        //console.log("this.comp",self.comp);
        resolve(self.comp);
      }
      self.refresh++;
      self.$forceUpdate();
    });

  },
  methods:{

    getComp(resolve, reject) {
      //console.log("this.comp",this.comp);
      resolve(this.comp);
    },

    build() {
      var self = this;
    },

    acao() {
      console.log("acao");
    },

    botao() {
      console.log("botao");
      var self = this;
    },

    iniciar(data) {
      data.cbfunc(this,rdb);
    }

  }
}
</script>

<style scoped>
</style>
